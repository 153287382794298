import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/seva/Orphangevisit/1.jpg';
import p2 from 'assests/Photos/seva/Orphangevisit/2.jpg';
import p3 from 'assests/Photos/seva/Orphangevisit/3.jpg';
import p4 from 'assests/Photos/seva/Orphangevisit/4.jpg';
// import p5 from 'assests/Photos/seva/shramdhan/5.jpg';
// import p6 from 'assests/Photos/seva/shramdhan/6.jpg';
// import p7 from 'assests/Photos/seva/shramdhan/7.jpg';
// import p8 from 'assests/Photos/seva/shramdhan/8.jpg';
// import p9 from 'assests/Photos/seva/shramdhan/9.jpg';
// import p10 from 'assests/Photos/seva/shramdhan/10.jpg';
// import p11 from 'assests/Photos/seva/shramdhan/11.jpg';




import { Typography } from '@mui/material';
import Container from 'components/Container';
import SideBar from 'views/SEWA/SideBar';
import { Sidebar2023 } from 'views/ScintillaOfArt2023/components';
const OrphangeVisit2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 2,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 2,
    },
    // {
    //       src: p5,
    //       source: p5,
    //       rows: 1,
    //       cols: 1,
    //     },
    //     {
    //       src: p6,
    //       source: p6,
    //       rows: 2,
    //       cols: 1,
    //     },
    //     {
    //       src: p7,
    //       source:p7,
    //       rows: 1,
    //       cols: 1,
    //     },
    //     {
    //       src: p8,
    //       source: p8,
    //       rows: 1,
    //       cols: 1,
    //     },
    //     {
    //       src: p9,
    //       source: p9,
    //       rows: 1,
    //       cols: 1,
    //     },
    //     {
    //           src: p10,
    //           source: p10,
    //           rows: 1,
    //           cols: 1,
    //         },
    //         {
    //           src: p11,
    //           source: p11,
    //           rows: 1,
    //           cols: 1,
    //         },
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      ORPHANAGE VISIT 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 9	  &  Date: 18 NOVEMBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        On Saturday, 18th November 2023, students of Class 9 visited Sneha Ghar, Shankara home and Srirampura orphanage homes. It was really a wonderful experience for our students to spend quality time with these under-privileged children. The students were greeted with warm smiles and were welcomed enthusiastically by the residents of these homes. To have meaningful interaction, students organised various fun games and other cultural activities. They donated notebooks, pencil pouches and pen-stands made by them. Later on, food packets were given to the children which brought a huge smile on their faces.
        <br></br>
        The visit was a heart-warming and transformative experience for our students. It reinforced the values of compassion and social responsibility for the needy ones. It was indeed a great experience for them as they realised the joy of giving and making others happy.  
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Climate change is the Everest of all problems, the thorniest challenge facing humankind - Lewis Gordon Pugh”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023/>
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default OrphangeVisit2023;



